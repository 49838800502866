/**
 * Created by slava on 8/5/19
 */

GoalView.prototype.ctor = function (goal, icon, options) {
    cc.Node.prototype.ctor.call(this);

    options = options || {};

    this.goal = goal;
    this.icon = icon;
    if (icon.cell) {
        this.cell = icon.cell;
    }

    var styles = this.getStyles();
    this.setContentSize(styles.width, styles.height);
    this.setAnchorPoint(0.5, 0.5);

    var actorStyles = cleverapps.clone(cleverapps.styles.BaseGoalView.actor, true);

    var spineId = Game.currentGame.enemies[this.goal.type];
    var selectedActorResources = cleverapps.styles.BaseGoalView.enemies[spineId];

    actorStyles.spine = selectedActorResources.spine;
    actorStyles.sfx = selectedActorResources.sfx;
    actorStyles.spineId = spineId;
    if (this.cell && this.cell.getColor && this.cell.getColor()) {
        actorStyles.skin = this.cell.getColor();
    }
    if (options.spine) {
        actorStyles.spine = options.spine;
    }
    if (options.skin) {
        actorStyles.skin = options.skin;
    }

    this.actor = new Actor(actorStyles);
    this.actor.setPositionRound(this.width / 2, this.height / 2);
    this.actor.setScale(actorStyles.scale || 1);
    this.actor.baseScale = actorStyles.scale || 1;
    this.addChild(this.actor);
    icon.scale = cleverapps.styles.BaseGoalView.icon.scale;

    this.life = new RpgLifeView({
        icon: icon,
        amount: goal.target,
        object: this.actor,
        hurtAnimations: [Actor.ANIMATIONS.HURT, Actor.ANIMATIONS.HURT2],
        bonus: goal.isBonusElement()
    });

    cleverapps.UI.onClick(this.actor, function () {
        this.actor.animate(cleverapps.Random.mathChoose(this.life.hurtAnimations));
        if (!this.goal.done) {
            this.goalCollectSound();
        }
    }.bind(this));

    goal.on("update", this.createListener(this.update.bind(this)));
    goal.on("beforeBonus", this.createListener(this.beforeAnimateBonus.bind(this)));
    goal.on("bonus", this.createListener(this.animateBonus.bind(this)));
    goal.on("onCollect", this.createListener(this.onCollect.bind(this)));
    goal.on("collect", this.createListener(this.animateCollect.bind(this)));
    goal.on("attack", this.createListener(this.attack.bind(this)));
};

GoalView.prototype.attack = function () {
    this.actor.animate(Actor.ANIMATIONS.ENEMY_ATTACK);
};

GoalView.prototype.getTarget = function () {
    return this.goal.done ? this.life.getTarget() : this.actor;
};

GoalView.prototype.update = function (delta, options) {
    var amount = this.goal.getRemaining();
    if (delta) {
        amount = this.amountNumber - delta;
    }
    this.amountNumber = amount;
    this.life.updateAmount(amount, options);

    var styles = cleverapps.styles.BaseGoalView.flag;
    if (styles && this.amountNumber === 0) {
        var flag = new cleverapps.Spine(styles.json);
        this.addChild(flag);
        flag.setPositionRound(styles);
        flag.setAnimation(0, "animation", false);
    }
};

GoalView.prototype.setCompleted = function () {
    cleverapps.audio.playSound(bundles.game.urls.goal_completed_effect);
};

GoalView.prototype.setIncompleted = function () {
};

GoalView.prototype.setFailed = function () {

};

GoalView.prototype.animateScale = function () {
};

GoalView.prototype.highlight = function () {
};

GoalView.prototype.animateSmile = cleverapps.extendFunc(GoalView.prototype.animateSmile, function () {
    this._super.apply(this, arguments);

    if (Game.currentGame) {
        if (cleverapps.config.name !== "adventure") { // attack animations in adventure contain exclamations inside, without this check
            // there will be too many exclamations
            Game.currentGame.heroAnimation(cleverapps.Random.mathChoose(cleverapps.styles.BaseGoalView.actor.attacks));
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BaseGoalView, {
    enemies: [
        {
            spine: bundles.enemy0.jsons.enemy0,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy1.jsons.enemy1,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy2.jsons.enemy2,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy3.jsons.enemy3,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        }
    ],

    actor: {
        attacks: [Actor.ANIMATIONS.ATTACK1, Actor.ANIMATIONS.ATTACK2, Actor.ANIMATIONS.ATTACK3],
        size: {
            width: 100,
            height: 120
        },
        position: {
            x: { align: "right", dx: 0 },
            y: { align: "top", dy: 0 }
        },
        skin: "regular"
    },

    icon: {
        scale: 0.6
    }
});
