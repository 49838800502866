/**
 * Created by vladislav on 04.09.2019
 */

BaseTileView.showUp = function (options) {
    this.setScale(1);
    if (options && options.callback) {
        options.callback();
    }
};

BaseTileView.beforeShowUpAnimation = function () {

};