/**
 * Created by slava on 13/3/19
 */

GameScene.prototype.addScoreView = function () {

};

ComingSoonEpisodeView.prototype.addBackground = function () {

};