/**
 * Created by slava on 8/5/19
 */

["a", "b", "c", "d", "e", "w"].forEach(function (color) {
    Game.GOAL_ENEMY_TYPES[color] = [0, 1, 2, 3];
});

Game.GOAL_ENEMY_TYPES[NutCell.GOAL_ID] = 7;
Game.GOAL_ENEMY_TYPES[IceTile.GOAL_ID] = 8;
Game.GOAL_ENEMY_TYPES[MouseCell.GOAL_ID] = 9;
Game.GOAL_ENEMY_TYPES[FishCell.GOAL_ID] = 10;
Game.GOAL_ENEMY_TYPES[FlowerCell.GOAL_ID] = 11;
Game.GOAL_ENEMY_TYPES[Mark.GOAL_ID] = 12;
Game.GOAL_ENEMY_TYPES[CoinCell.GOAL_ID] = 14;