/**
 * Created by vladislav on 27.09.2019
 */

RpgLifeView.prototype.animateDead = function () {
    var animation = cleverapps.Random.mathChoose(this.hurtAnimations);
    this.object.alive = true;
    this.object.animate(animation, {
        idleAnimation: Actor.ANIMATIONS.DIE_IDLE
    });
};