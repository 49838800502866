/**
 * Created by slava on 9/8/17
 */

FieldBoosterView.prototype.createIcon = function () {
    var styles = cleverapps.styles.FieldBoosterView;

    var actorStyles = styles.actor;
    this.setContentSize2(actorStyles.size);

    actorStyles.spine = [bundles.game.jsons.cellbooster_spine_json, bundles.game.jsons.linebooster_spine_json, bundles.game.jsons.colorbooster_spine_json][this.booster.id];

    this.icon = new Actor(actorStyles);
    this.addChild(this.icon);
};

FieldBoosterView.prototype.showActivated = function () {
    this.icon.spine.setAnimation(0, "activation", true);
};

FieldBoosterView.prototype.showDeactivated = function () {
    this.icon.spine.setAnimation(0, "idle", true);
};

cleverapps.overrideStyles(cleverapps.styles.FieldBoosterView, {
    actor: {
        size: {
            width: 150,
            height: 180
        },

        position: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        },

        spinePosition: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    amount: {
        x: -120,
        y: 0
    }
});