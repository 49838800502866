/**
 * Created by vladislav on 25.09.2019
 */

cleverapps.styles.dialogues = {
    "finishLevel1": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel1.Text1",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel1.Text2",
                person: { role: "girl", orientation: "right" }
            }
        ],
        bundles: ["dialogues", "game"]
    },

    "finishLevel3": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel3.Text1",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel3.Text2",
                person: { role: "girl", orientation: "right" }
            }
        ],
        bundles: ["dialogues", "game"]
    },

    "finishLevel6": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel6.Text1",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel6.Text2",
                person: { role: "girl", orientation: "right" }
            },

            {
                text: "Dialogues.FinishLevel6.Text3",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel6.Text4",
                person: { role: "girl", orientation: "right" }
            }
        ],
        bundles: ["dialogues", "game"]
    },

    "finishLevel10": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel10.Text1",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel10.Text2",
                person: { role: "girl", orientation: "right" }
            }
        ],
        bundles: ["dialogues", "game"]
    },

    "finishLevel14": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel14.Text1",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel14.Text2",
                person: { role: "girl", orientation: "right" }
            },

            {
                text: "Dialogues.FinishLevel14.Text3",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel14.Text4",
                person: { role: "girl", orientation: "right" }
            }
        ],
        bundles: ["dialogues", "game"]
    },

    "finishLevel16": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel16.Text1",
                person: "girl"
            },
            {
                text: "Dialogues.FinishLevel16.Text2",
                person: { role: "dragon", orientation: "right" }
            },
            {
                text: "Dialogues.FinishLevel16.Text3",
                person: "girl"
            },
            {
                text: "Dialogues.FinishLevel16.Text4",
                person: { role: "dragon", orientation: "right" }
            }
        ],
        bundles: ["dialogues", "game"]
    },

    "finishLevel21": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel21.Text1",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel21.Text2",
                person: { role: "girl", orientation: "right" }
            },

            {
                text: "Dialogues.FinishLevel21.Text3",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel21.Text4",
                person: { role: "girl", orientation: "right" }

            },
            {
                text: "Dialogues.FinishLevel21.Text5",
                person: "dragon"
            }
        ],
        bundles: ["dialogues", "game"]
    },

    "finishLevel29": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel29.Text1",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel29.Text2",
                person: { role: "girl", orientation: "right" }
            },

            {
                text: "Dialogues.FinishLevel29.Text3",
                person: "dragon"
            }
        ],
        bundles: ["dialogues", "game"]
    },

    "finishLevel40": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel40.Text1",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel40.Text2",
                person: { role: "girl", orientation: "right" }
            },

            {
                text: "Dialogues.FinishLevel40.Text3",
                person: "dragon"
            },
            {
                text: "Dialogues.FinishLevel40.Text4",
                person: { role: "girl", orientation: "right" }

            }
        ],
        bundles: ["dialogues", "game"]
    },

    "finishLevel46": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel46.Text1",
                person: "girl"
            },
            {
                text: "Dialogues.FinishLevel46.Text2",
                person: { role: "dragon", orientation: "right" }
            },
            {
                text: "Dialogues.FinishLevel46.Text3",
                person: "girl"
            },
            {
                text: "Dialogues.FinishLevel46.Text4",
                person: { role: "dragon", orientation: "right" }
            }
        ],
        bundles: ["dialogues", "game"]
    },

    "finishLevel59": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel59.Text1",
                person: "girl"
            },
            {
                text: "Dialogues.FinishLevel59.Text2",
                person: { role: "dragon", orientation: "right" }
            },
            {
                text: "Dialogues.FinishLevel59.Text3",
                person: "girl"
            },
            {
                text: "Dialogues.FinishLevel59.Text4",
                person: { role: "dragon", orientation: "right" }
            },

            {
                text: "Dialogues.FinishLevel59.Text5",
                person: "girl"
            }
        ],
        bundles: ["dialogues", "game"]
    }

};
