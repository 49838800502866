/**
 * Created by decipaliz on 29.03.2024
 */

Actor.prototype.showUpAnimation = function (silent) {
    if (silent) {
        this.setVisible(true);
        return;
    }

    var scene = cleverapps.scenes.getRunningScene();
    var styles = cleverapps.styles.Actor;
    var targetPosition = this.getPosition();

    switch (cleverapps.resolution.mode) {
        case cleverapps.WideMode.HORIZONTAL: case cleverapps.WideMode.SQUARE:
            this.setPositionRound(scene.width + this.width * 2, this.y);
            break;
        case cleverapps.WideMode.VERTICAL:
            this.setPositionRound(this.x, -scene.height - this.height * 2);
            break;
    }

    this.showUpAction = new cc.Sequence(
        new cc.Show(),
        new cc.DelayTime(styles.showDelay),
        new cc.MoveTo(0.6, targetPosition).easing(cc.easeElasticOut(1.5))
    ).setFinalize(function () {
        this.setPositionRound(targetPosition);
        delete this.showUpAction;
    }.bind(this));

    this.runAction(this.showUpAction);
};