/**
 * Created by mac on 7/22/17.
 */

Goals.prototype.attack = function () {
    var bestType = undefined;
    for (var type in this.elements) {
        if (!this.elements[type].done) {
            if (this.elements[type].amountAttacks === undefined) {
                this.elements[type].amountAttacks = 0;
            }
            if (bestType === undefined || this.elements[type].amountAttacks < this.elements[bestType].amountAttacks) {
                bestType = type;
            }
        }
    }

    if (bestType !== undefined) {
        this.elements[bestType].amountAttacks++;
        this.elements[bestType].trigger("attack");
        return true;
    }

    return false;
};

cleverapps.goalCollectSound = (function () {
    var soundLevel = 0;
    var decreaseInterval = 400;
    var lastTime = Date.now();

    return function () {
        var decrease = Math.floor((Date.now() - lastTime) / decreaseInterval);
        soundLevel = Math.min(2, Math.max(0, soundLevel + 1 - decrease));
        lastTime = Date.now();

        cleverapps.audio.playSound(bundles.game.urls["goal_inc_effect_" + soundLevel], {
            throttle: 200
        });
    };
}());