/**
 * Created by vladislav on 21.08.2019
 */
ComingSoonEpisodeView.prototype.customize = cleverapps.extendFunc(ComingSoonEpisodeView.prototype.customize, function () {
    this._super.apply(this, arguments);

    var styles = cleverapps.styles.EpisodeView.coming_soon.clouds;
    var clouds = new cleverapps.Spine(bundles.episodes_resources.jsons.locked_json);
    clouds.setScale(1.4);
    clouds.setAnimation(0, "close", true);
    this.content.addChild(clouds);
    clouds.setPositionRound(this.content.width / 2 + styles.offset.x, this.content.height / 2 + styles.offset.y);
    clouds.setLocalZOrder(this.content.getLocalZOrder() - 1);
});
