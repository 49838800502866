/**
 * Created by decipaliz on 12.03.2024
 */

Match3.prototype.showScreen = function (f, silent) {
    this.trigger("showPanels", silent);
    this.boosters.trigger("show", silent);
    this.trigger("showActor", silent);
    this.trigger("showMovesView", silent);

    cleverapps.meta.compound(f, [
        function (f) {
            this.goals.trigger("showUpAnimationStart", f, silent);
        }.bind(this),

        function (f) {
            this.field.trigger("showUpFloor", f, silent);
        }.bind(this),

        function (f) {
            this.field.showUpCells(f, silent);
        }.bind(this)
    ]);
};