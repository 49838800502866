/**
 * Created by vladislav on 03.09.2019
 */

cleverapps.overrideStyles(HeroesLibrary.byColors, {
    a: {
        shootAlgorithm: "blast",
        blast_animation: bundles.heroes.jsons.common_hero_spine_effect_json,
        createAnimation: "create"
    },
    b: {
        createAnimation: "create"
    },
    c: {
        shootAlgorithm: "blast",
        blast_animation: bundles.heroes.jsons.common_hero_spine_effect_json,
        createAnimation: "create"
    },
    d: {
        shootAlgorithm: "blast",
        blast_animation: bundles.heroes.jsons.common_hero_spine_effect_json,
        action_delay: 600,
        createAnimation: "create"
    },
    e: {
        shootAlgorithm: "throw",
        blast_animation: bundles.heroes.jsons.E_hero_spine_effect_json,
        blast_explde_animation_name: "explode",
        applyEasing: function (action) {
            action.easing(cc.easeIn(2));
        },

        createAnimation: "create"
    },
    w: {
        createAnimation: "create"
    }
});