/**
 * Created by vladislav on 6/4/19
 */

ComingSoonEpisodeView.prototype._addActivity = ComingSoonEpisodeView.prototype.addActivity;
ComingSoonEpisodeView.prototype.addActivity = function (items) {
    items.reverse();
    this._addActivity(items);
};

cleverapps.overrideStyles(cleverapps.styles.EpisodeView, {
    coming_soon: {
        margin: 10,

        positions: [
            { x: 0, y: 80 },
            { x: 50, y: 170 },
            { x: 50, y: 170 }
        ],

        animation: {
            text: {
                x: { align: "center" },
                y: { align: "bottom", dy: 30 },
                width: 450,
                height: 90
            }
        },

        activities: {
            animation: false,
            itemsDirection: cleverapps.UI.HORIZONTAL,
            direction: cleverapps.UI.VERTICAL,
            margin: 10,

            button: {
                width: 210
            },

            text: {
                width: 300,
                height: 100,
                horizontalAlignment: cc.TEXT_ALIGNMENT_LEFT,
                verticalAlignment: cc.VERTICAL_TEXT_ALIGNMENT_CENTER,
                cupMsg: "Play"
            }
        },

        text: {
            width: [520, 650, 650]
        }
    }
});
