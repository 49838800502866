var _onMoveChangeMoves = Game.prototype.onMoveChangeMoves;
Game.prototype.onMoveChangeMoves = function () {
    if (!cleverapps.styles.BaseGoalView.actor.disableAttack) {
        return;
    }

    _onMoveChangeMoves.apply(this, arguments);
};

Game.prototype.enemyAttackStage = function () {
    if (cleverapps.styles.BaseGoalView.actor.disableAttack) {
        return;
    }

    if (this.tutorial && !this.tutorial.finished) {
        return;
    }

    if (this.enemiesAttack) {
        this.enemiesAttack = false;

        Game.currentGame.counter.setTimeout(function () {
            if (!this.goals.attack()) {
                return;
            }

            Game.currentGame.counter.setTimeout(function () {
                this.decMoves();
                this.shakeField();
            }.bind(this), Game.ENEMIES_ATTACK_TIME);
        }.bind(this), Game.BEFORE_ENEMIES_ATTACK_TIME);
    }
};

var oldUpdateOneTimePerMoveFeatures = Game.prototype.updateOneTimePerMoveFeatures;
Game.prototype.updateOneTimePerMoveFeatures = function () {
    oldUpdateOneTimePerMoveFeatures.call(this);
    this.enemiesAttack = true;
};

Game.SelectEnemies = function (level) {
    var enemies = level.getLevelEnemies();

    var selectRandom = function (goal, type, index) {
        if (type === undefined) {
            type = [];
            for (var i = 0; i < cleverapps.styles.BaseGoalView.enemies.length; i++) {
                if (i === Game.GOAL_ENEMY_TYPES[CoinCell.GOAL_ID]) {
                    continue;
                }

                type.push(i);
            }
        }

        var seed = (level.getHumanReadableNumber() + index) * 127;
        type = cleverapps.substract(type, Object.values(enemies));
        return cleverapps.Random.choose(type, seed);
    }; 

    Object.keys(enemies).forEach(function (goal, index) {
        var type = enemies[goal];
        if (typeof type === "number") {
            return;
        }

        enemies[goal] = selectRandom(goal, type, index);
    });

    return enemies;
};

Game.BEFORE_ENEMIES_ATTACK_TIME = 200;
Game.ENEMIES_ATTACK_TIME = 600;
Game.MESSAGE_TIME = 800;

Game.GOAL_ENEMY_TYPES = {};