/**
 * Created by iamso on 31.05.19.
 */

BoosterCellView.prototype.ctor = function (boosterCell) {
    cc.Node.prototype.ctor.call(this);

    this.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
    this.setAnchorPoint(0.5, 0.5);

    var animation = this.getImage(boosterCell);
    this.image = animation;
    animation.setPosition(this.width / 2, this.height / 2);
    animation.setScale(cleverapps.styles.BoosterCellView.scale);
    this.addChild(animation);

    animation.setAnimation(0, "activation", true);

    this.initialize(boosterCell);
};

BoosterCellView.prototype.getImage = function (boosterCell) {
    var animation = [bundles.game.jsons.cellbooster_spine_json, bundles.game.jsons.linebooster_spine_json, bundles.game.jsons.colorbooster_spine_json][boosterCell.type];
    return new cleverapps.Spine(animation);
};

cleverapps.overrideStyles(cleverapps.styles.BoosterCellView, {
    scale: 0.7
});