/**
 * Created by vladislav on 8/1/19
 */

cleverapps.styles.comicses = {
    begin: {
        stages: [
            {
                showAnimation: "trans_1",
                idleAnimation: "idle_1"
            },
            {
                showAnimation: "trans_2",
                idleAnimation: "idle_2",
                dialogueStages: 3
            },
            {
                showAnimation: "trans_3",
                idleAnimation: "idle_3"
            },
            {
                showAnimation: "trans_4"
            }
        ],

        dialogue: [
            {
                text: "Comics.Begin.Text1",
                person: false
            },
            {
                text: "Comics.Begin.Text2",
                person: {
                    role: "dragon",
                    orientation: "right"
                },
                person2: "girl"
            },
            {
                text: "Comics.Begin.Text3",
                person: "girl",
                person2: {
                    role: "dragon",
                    orientation: "right"
                }
            },
            {
                text: "Comics.Begin.Text4",
                person: {
                    role: "dragon",
                    orientation: "right"
                },
                person2: "girl"
            },
            {
                text: "Comics.Begin.Text5",
                person: "girl",
                person2: {
                    role: "dragon",
                    orientation: "right"
                }
            },
            {
                text: "Comics.Begin.Text6",
                person: false
            }
        ],

        bundles: ["comicses"],
        json: bundles.comicses.jsons.begin_json,
        onFinish: "game"
    }
};
