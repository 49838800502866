/**
 * Created by vladislav on 08.08.2019
 */

IslandView.prototype.show = function () {
    if (this.hideInProgress) {
        this.needToShow = true;
        return;
    }

    if (this.showInProgress) {
        delete this.needToHide;
        return;
    }

    if (!this.lockedIdle) {
        return;
    }

    this.locked.setAnimation(0, "transition", false);
    this.showInProgress = true;

    this.locked.setCompleteListener(function () {
        this.showInProgress = false;

        this.setLockedIdle(false);

        if (this.needToHide) {
            delete this.needToHide;
            this.hide();
        }
    }.bind(this));

    this.toggleLabel(true);
};

IslandView.prototype.hide = function () {
    if (this.showInProgress) {
        this.needToHide = true;
        return;
    }

    if (this.hideInProgress) {
        delete this.needToShow;
        return;
    }

    if (this.lockedIdle) {
        return;
    }

    this.locked.setVisible(true);
    this.locked.setAnimation(0, "transition_close", false);
    this.hideInProgress = true;

    this.locked.setCompleteListener(function () {
        this.hideInProgress = false;

        this.setLockedIdle(true);

        if (this.needToShow) {
            delete this.needToShow;
            this.show();
        }
    }.bind(this));

    this.toggleLabel(false);
};

IslandView.prototype.toggleLabel = function (on) {
    if (!this.label) {
        return;
    }
    this.label.stopAllActions();
    if (on) {
        this.label.runAction(new cc.Sequence(
            new cc.ScaleTo(0.3, 1.2),
            new cc.ScaleTo(0.2, 1.0)
        ));
    } else {
        this.label.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.2),
            new cc.ScaleTo(0.3, 0)
        ));
    }
};

IslandView.prototype.setLockedIdle = function (lockedIdle) {
    if (this.lockedIdle === lockedIdle) {
        return;
    }
    this.lockedIdle = lockedIdle;
    this.locked.setVisible(this.lockedIdle);
    if (this.lockedIdle) {
        this.locked.setAnimation(0, "close", true);
    }
    this.locked.setCompleteListener();
};

IslandView.prototype.animateOpen = function (callback) {
    this.addHighlight();
    this.removeStatus();

    this.makeIslandClickable();

    this.runAction(new cc.Sequence(new cc.DelayTime(0.75), new cc.CallFunc(function () {
        if (!this.label) {
            this.label = new CurrentEpisodeLabel(this.pageNo);
            this.addChild(this.label);
        }
        this.label.setVisible(true);
        this.label.labelText.setVisible(false);
        this.label.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                this.label.labelText.setVisible(true);
            }.bind(this))
        ));
        this.label.bgAnimation.setAnimation(0, "open", false);

        this.addProgress();

        callback();
    }.bind(this))));

    return true;
};

IslandView.prototype.onScroll = function () {
    var offset = this.page.offsetFromCenter;
    if (offset > -1.3 && offset < 0.8) {
        this.show();
    } else {
        this.hide();
    }
};
