/**
 * Created by slava on 2/5/19
 */

var RpgLifeView = cc.Node.extend({
    ctor: function (options) {
        this.maxAmount = options.maxAmount || options.amount;
        this.amount = options.amount;
        this.bonus = options.bonus;

        var styles = cleverapps.styles.RpgLifeView;

        this._super();

        this.hurtAnimations = options.hurtAnimations || [Actor.ANIMATIONS.HURT];

        this.object = options.object;
        this.object.addChild(this);

        this.setPositionRound(styles);

        var amount = this.bonus ? this.maxAmount - this.amount : this.amount;
        var bar = this.bar = new ScaledProgressBar({
            progress: bundles.progress_bar.frames.bar_life,
            background: bundles.progress_bar.frames.bg_life,
            barText: {
                text: "%from%",
                font: cleverapps.styles.FONTS.RPG_LIFE_VIEW_TEXT
            }
        });
        bar.setCascadeColorEnabled(true);
        bar.setGoal(this.maxAmount);
        bar.setPercentage(amount);
        bar.setLength(styles.bar.width);
        if (styles.bar.color) {
            bar.setColor(styles.bar.color);
        }
        this.addChild(bar);

        var icon = this.icon = options.icon;
        icon.setAnchorPoint(0.5, 0.5);
        this.addChild(icon);
        icon.setPositionRound(styles.icon);
    },

    getTarget: function () {
        return this.bar.barText;
    },

    isLow: function () {
        return this.amount < this.maxAmount * 0.3;
    },

    animateDead: function () {
        this.object.shake();
    },

    updateLife: function (amount) {
        if (this.amount !== amount && (this.bonus ? this.bar.from > this.maxAmount - amount : this.bar.from < amount)) {
            return;
        }

        var styles = cleverapps.styles.RpgLifeView;

        amount = this.bonus ? this.maxAmount - amount : amount;
        this.bar.setGoal(this.maxAmount);
        this.bar.setPercentage(amount);
        var color = this.isLow() ? styles.bar.colorLow : styles.bar.color;
        if (color) {
            this.bar.setColor(color);
        }
    },

    updateAmount: function (amount, options) {
        options = options || {};
        if (amount < 0) {
            amount = 0;
        }

        if (amount === this.amount && !options.silent) {
            if (amount === 0) {
                this.runAction(new cc.Sequence(
                    new cc.DelayTime(options.delay || 0),
                    new cc.CallFunc(function () {
                        this.animateDead();
                    }.bind(this))
                ));
            }
            return;
        }

        var styles = cleverapps.styles.RpgLifeView;

        var diff = amount - this.amount;

        if (this.maxAmount < amount) {
            this.maxAmount = amount;
        }

        var dead = false;
        if (this.amount === 0) {
            dead = true;
        }
        this.amount = amount;

        var animateOptions = {};
        if (cleverapps.config.name === "runes" && options.actionBefore && this.object.alive && !this.object.animationRunning) {
            animateOptions.callback = this.updateLife.bind(this, amount);
        } else {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(options.delay || 0),
                new cc.CallFunc(function () {
                    this.updateLife(amount);
                }.bind(this))
            ));
        }

        var actions = new cc.Sequence(
            new cc.DelayTime(options.delay || 0),
            new cc.CallFunc(function () {
                var animation = Actor.ANIMATIONS.IDLE;
                if (diff < 0) {
                    animation = cleverapps.Random.mathChoose(this.hurtAnimations);
                    if (amount === 0) {
                        if (options.silent) {
                            animation = Actor.ANIMATIONS.DIE_IDLE;
                        } else {
                            animation = Actor.ANIMATIONS.DIE;
                            this.runAction(new cc.ScaleTo(0.3, 0));
                        }
                    }
                } else if (!options.silent) {
                    animation = Actor.ANIMATIONS.HEAL;
                    if (dead) {
                        animation = Actor.ANIMATIONS.REVIVE;
                        this.runAction(new cc.ScaleTo(0.3, 1));
                    }
                }

                this.object.animate(animation, animateOptions);
            }.bind(this))
        );

        if (!options.silent && styles.delta) {
            var font = cleverapps.styles.FONTS.RPG_LIFE_DELTA_RED;
            if (diff > 0 || !styles.delta.hurtOnCollect) {
                diff = diff < 0 ? -1 * diff : diff;
                font = cleverapps.styles.FONTS.RPG_LIFE_DELTA_GREEN;
            }

            actions = new cc.Sequence(
                actions,
                AnimationsLibrary.animateDelta(diff, {
                    target: this.object,
                    x: this.object.width / 2 * (1.5 - Math.random()),
                    y: this.object.height * 3 / 4 * (1.5 - Math.random()),
                    font: font
                })
            );
        }
        this.runAction(actions);
    }
});

cleverapps.styles.RpgLifeView = {
    icon: {
        x: { align: "center", dx: -100 },
        y: { align: "center" }
    },

    bar: {
        width: 200,
        color: cleverapps.styles.COLORS.LIFE_GREEN,
        colorLow: cleverapps.styles.COLORS.COLOR_RED,
        x: 0,
        y: 0
    },

    delta: {
        hurtOnCollect: true
    },

    x: { align: "center" },
    y: { align: "bottom", dy: -40 }
};
